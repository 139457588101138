import { Component, ViewEncapsulation, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';

import { LoginService } from './services/login.service';
import { UrlService } from './services/url.service';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { filter, map } from 'rxjs';


@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
  encapsulation: ViewEncapsulation.None
})
export class AppComponent implements OnInit {
  isAuthAndAuthorized = false;
  isUrlValid = true;
  isExecutiveLoginLatam = false;
  showMainLayout = true;
  showLoginLatam = false;

  private language = 'en';
  private languages = ['en', 'es', 'pt'];

  private readonly WAIVERS_CORTESY: string = 'waivers/latam';
  private readonly WAIVERS_POLICY: string = 'waivers/policies';
  private readonly CORRECT_NAME: string = 'correct-name';

  constructor(
    private loginService: LoginService,
    private translateService: TranslateService,
    private router: Router,
    private urlservice: UrlService,
    private activatedRoute: ActivatedRoute) {

    if (navigator.language) {
      if (this.languages.find(f => f === navigator.language.substring(0, 2))) {
        this.language = navigator.language.substring(0, 2);
      }
    }

    if (!localStorage.getItem('language')) {
      this.translateService.use(this.language);
    } else {
      this.translateService.use(localStorage.getItem('language'));
    }

    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => this.activatedRoute),
      map(route => {
        while (route.firstChild) route = route.firstChild;
        return route;
      }),
    ).subscribe((event) => {
      const actualRoute = this.router.url;

      const routesNewLayout = [
        this.WAIVERS_CORTESY,
        this.WAIVERS_POLICY,
        this.CORRECT_NAME,
        'agency/correct-name',
        'agency/fare-regulation',
        'latam/fare-regulation',
        'outsourcing/fare-regulation'
      ];

      this.showMainLayout =  !routesNewLayout.some(route => actualRoute.includes(route));
    });
  }

  ngOnInit() {
    this.loginService.loginState
      .subscribe((loginState: boolean) => {
        this.isAuthAndAuthorized = loginState;
        this.getUrlErrorCode();
      }
      );
    this.isLogin();
  }


  private getUrlErrorCode(): void {
    this.urlservice.getUrlErrorCode().subscribe(
      data => {
        if (data.snapshot.data !== undefined) {
          if (data.snapshot.data.code === '401' || data.snapshot.data.code === '403' || data.snapshot.data.code === '404') {
            this.isUrlValid = false;
            this.urlservice.setErrorCode(data.snapshot.data.code);
          }
          else if (data.snapshot.data.code === 'latam-mobile-executive') {
            this.isExecutiveLoginLatam = true;
          }
        } else {
          this.isUrlValid = true;
        }
      }
    );
  }

  isLogin() {
    if (localStorage.getItem('idToken') && localStorage.getItem('sessionToken')) {
      this.loginService.setCurrentLoginState(true);
    } else {
      this.loginService.setCurrentLoginState(false);
    }
  }
}
