import { ConstantsService } from './../services/constants.service';
import { Role } from './role.model';

export class SessionInfo {
  // Session types
  public static readonly LATAM_SESSION_TYPE = 'LATAM';
  public static readonly AGENCY_SESSION_TYPE = 'B2C';
  public static readonly EXTERNAL_SESSION_TYPE = 'LATAM-EXT';

  // Agency roles
  public static readonly AGENCY_AGENT_ROLE: string = 'AGENT';
  public static readonly AGENCY_ADMIN_MTZ_ROLE: string = 'ADMIN_MTZ';
  public static readonly AGENCY_MANUAL_MTZ_ROLE: string = 'MANUAL_MTZ';
  public static readonly AGENCY_AGENT_MTZ_ROLE: string = 'AGENT_MTZ';
  public static readonly AGENCY_AGENT_MTZ_SWA_ROLE: string = 'AGENT_MTZ_SWA';
  public static readonly AGENCY_AGENT_MTZ_CWA_ROLE: string = 'AGENT_MTZ_CWA';
  public static readonly AGENCY_ADMIN_SUC_ROLE: string = 'ADMIN_SUC';
  public static readonly AGENCY_AGENT_SUC_ROLE: string = 'AGENT_SUC';

  private static readonly AGENCY_ROLE_ORDER: string[] = [
    SessionInfo.AGENCY_ADMIN_MTZ_ROLE,
    SessionInfo.AGENCY_MANUAL_MTZ_ROLE,
    SessionInfo.AGENCY_AGENT_MTZ_ROLE,
    SessionInfo.AGENCY_AGENT_MTZ_SWA_ROLE,
    SessionInfo.AGENCY_AGENT_MTZ_CWA_ROLE,
    SessionInfo.AGENCY_ADMIN_SUC_ROLE,
    SessionInfo.AGENCY_AGENT_SUC_ROLE
  ];

  // Latam roles
  public static readonly LATAM_ADMIN_ROLE: string = 'ADMIN';
  public static readonly LATAM_ADMIN_POS_ROLE: string = 'ADMIN_POS';
  public static readonly LATAM_USER_POS_ROLE: string = 'USER_POS';
  public static readonly LATAM_ADMIN_SALES_ROLE: string = 'ADMIN_SALES';
  public static readonly LATAM_USER_SALES_ROLE: string = 'USER_SALES';
  public static readonly LATAM_ADMIN_PIA_ROLE: string = 'ADMIN_PIA';
  public static readonly LATAM_EXTERN_USER_ROLE: string = 'USER_EXT';
  public static readonly LATAM_BDA_USER_ROLE: string = 'USER_BDA';

  private static readonly ROLE_ORDER: string[] = [
    SessionInfo.LATAM_ADMIN_ROLE,
    SessionInfo.LATAM_ADMIN_POS_ROLE,
    SessionInfo.LATAM_ADMIN_SALES_ROLE,
    SessionInfo.LATAM_USER_POS_ROLE,
    SessionInfo.LATAM_USER_SALES_ROLE,
    SessionInfo.LATAM_ADMIN_PIA_ROLE,
    SessionInfo.LATAM_EXTERN_USER_ROLE,
    SessionInfo.LATAM_BDA_USER_ROLE
  ];

  public readonly name: string;
  public readonly position: string;
  public readonly role: string;
  public readonly secondaryRole: string;
  public readonly roleName: string;
  public readonly secondaryRoleName: string;
  public readonly agency: string;
  public readonly market: string[] | string;
  public readonly language: string;
  public readonly userId: number;
  public readonly marketId: number[] | number;
  public readonly allowedActions: Array<string>;
  public readonly sessionType: string;
  public readonly matrix_id: number;
  public readonly branch_office_id: number;
  public readonly is_matrix_user: boolean;
  public readonly latam_role_size: string;
  public readonly is_agree_termsconditions: boolean;
  public readonly isSelfManaged: boolean;
  public readonly showAgencyBudget: boolean;

  private latamRoleIndex: number;
  private agencyRoleIndex: number;
  private constantsService = new ConstantsService();

  public constructor(
    name?: string,
    position?: string,
    role?: string,
    secondaryRole?: string,
    roleName?: string,
    secondaryRoleName?: string,
    agency?: string,
    market?: string[],
    language?: string,
    userId?: number,
    agencyId?: number,
    marketId?: number[],
    allowedActions?: Array<string>,
    sessionType?: string,
    matrix_id?: number,
    branch_office_id?: number,
    is_matrix_user?: boolean,
    latam_role_size?: string,
    is_agree_termsconditions?: boolean,
    isSelfManaged?: boolean,
    showAgencyBudget?: boolean
  ) {
      this.name = name;
      this.position = position;
      this.role = role;
      this.secondaryRole = secondaryRole;
      this.roleName = roleName;
      this.secondaryRoleName = secondaryRoleName;
      this.agency = agency;
      this.market = market;
      this.language = language;
      this.userId = userId;
      this.marketId = marketId;
      this.allowedActions = allowedActions ? allowedActions : [];
      this.sessionType = sessionType;
      this.latamRoleIndex = SessionInfo.ROLE_ORDER.indexOf(this.role);
      this.agencyRoleIndex = SessionInfo.AGENCY_ROLE_ORDER.indexOf(this.role);
      this.matrix_id = matrix_id;
      this.branch_office_id = branch_office_id;
      this.is_matrix_user = is_matrix_user;
      this.latam_role_size = latam_role_size;
      this.is_agree_termsconditions = is_agree_termsconditions;
      this.isSelfManaged = isSelfManaged;
      this.showAgencyBudget = showAgencyBudget;
  }

  public isLatamSession(): boolean {
    return this.sessionType === SessionInfo.LATAM_SESSION_TYPE;
  }

  public isAgencySession(): boolean {
    return this.sessionType === SessionInfo.AGENCY_SESSION_TYPE;
  }

  public isLatamExternalSession(): boolean {
    return this.sessionType === SessionInfo.EXTERNAL_SESSION_TYPE;
  }

  public isLoginLatamExternalSession(): boolean {
    return this.role === SessionInfo.LATAM_EXTERN_USER_ROLE;
  }

  public isAgentUserAgency(): boolean {
    return this.isAgencySession() && (this.role === SessionInfo.AGENCY_AGENT_ROLE || this.secondaryRole === SessionInfo.AGENCY_AGENT_ROLE);
  }

  public isAdminAgencyGroupUser(): boolean {
    return this.isAgencySession() && this.role === SessionInfo.AGENCY_ADMIN_MTZ_ROLE;
  }

  public isManualAgencyGroupUser(): boolean {
    return this.isAgencySession() && this.role === SessionInfo.AGENCY_MANUAL_MTZ_ROLE;
  }

  public isAgencyGroupUser(): boolean {
    return this.isAgencySession() && this.role === SessionInfo.AGENCY_AGENT_MTZ_ROLE;
  }

  public isAdminBranchOfficeUser(): boolean {
    return this.isAgencySession() && this.role === SessionInfo.AGENCY_ADMIN_SUC_ROLE;
  }

  public isBranchOfficeUser(): boolean {
    return this.isAgencySession() && (this.role === SessionInfo.AGENCY_ADMIN_SUC_ROLE || this.role === SessionInfo.AGENCY_AGENT_SUC_ROLE);
  }

  public isAgentSuc(): boolean {
    return this.isAgencySession() && ( this.role === SessionInfo.AGENCY_AGENT_SUC_ROLE);
  }

  public isSuperAdmin(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_ADMIN_ROLE;
  }

  public isAdminUserLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_ADMIN_POS_ROLE;
  }

  public isSimpleUserLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_USER_POS_ROLE;
  }

  public isAdminSalesLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_ADMIN_SALES_ROLE;
  }

  public isUserSalesLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_USER_SALES_ROLE;
  }

  public isUserBdaLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_BDA_USER_ROLE;
  }

  public isUserRoleGreaterThan(roleCode: string) {
    let roleCodeIndex = SessionInfo.ROLE_ORDER.indexOf(roleCode);

    if (this.latamRoleIndex === 0) {
      return roleCodeIndex !== -1 && this.latamRoleIndex < roleCodeIndex;
    } else {
      return roleCodeIndex !== -1 && this.latamRoleIndex < roleCodeIndex && roleCodeIndex !== 5;
    }
  }

  public isUserRoleIdGreaterThan(roleId: number) {
    if (this.role === SessionInfo.LATAM_ADMIN_ROLE) {
      return this.latamRoleIndex + 1 < roleId;
    } else if (this.role === SessionInfo.LATAM_ADMIN_SALES_ROLE) {
      return this.latamRoleIndex + 1 <= roleId && roleId !== 4 && roleId !== 5;
    } else {
      return this.latamRoleIndex + 1 < roleId && roleId !== 4;
    }
  }

  public isAgencyRoleGreaterThan(roles: Role[]) {
    for (const role of roles) {
      if (role.systemOrigin) {
        if (role.systemOrigin.name === this.constantsService.systemName) {
          let roleCodeIndex = SessionInfo.AGENCY_ROLE_ORDER.indexOf(role.code);
          return this.agencyRoleIndex < roleCodeIndex;
        }
      }
    }

    return false;
  }

  public isAdminPiaUserLatam(): boolean {
    return this.isLatamSession() && this.role === SessionInfo.LATAM_ADMIN_PIA_ROLE;
  }

}
