import { Injectable } from '@angular/core';
import * as jwt_decode from 'jwt-decode';

import { Profile } from '../models/profile.model';

@Injectable()
export class ProfilingService {
  role_size: number;
  tokenTemp: string;
  profiles: Profile;
  decodedTokenTemp;


  entryToProperties: {[key: string]: Profile} = {
    CGESUSR: { createUserManagement: true },
    LGESUSR: { latamManagement: true, readUserManagement: true },
    AGESUSR: { updateUserManagment: true },
    BGESUSR: { deleteUserManagement: true },
    LFEEDLT: { latamManagement: true, readFeedback: true },
    CGESPPTO: { createBudgetManagement: true },
    AGESPPTO: { updateBudgetManagement: true },
    LAUTWAI: { latamManagement: true, readWaiverAutomation: true},
    AAUTWAI: { updateWaiverAutomation: true},
    LACTPWAI: { latamManagement: true, readWaiverTypeActivation: true},
    AACTPWAI: { updateWaiverTypeActivation: true },
    LGESFRD: { latamManagement: true, readFarediffManagement: true },
    AGESFRD: { updateFarediffManagement: true },
    CCARMAS: { createBulkLoad: true },
    LCARMAS: { latamManagement: true, readBulkLoad: true },
    CACTAGN: { createActivateAgency: true },
    LGESPPTO: { latamManagement: true, readBudgetManagement: true, createBudgetBulkLoad: true },
    LACTAGN: { agencyManagment: true, readActivateAgency: true},
    LREGAGN: { agencyManagment: true, readAgencyRegister: true },
    AREGAGN: { updateAgencyRegister: true },
    BREGAGN: { deleteAgencyRegister: true },
    CGEGRAGN: { createAgencyGroupManagment: true },
    LGEGRAGN: { agencyManagment: true, readAgencyGroupManagement: true },
    AGEGRAGN: { updateAgencyGroupManagement: true },
    LGWAIPOS: { waiverManagment: true, readWaiverPostSaleManagement: true },
    AGWAIPOS: { updateWaiverPostSaleManagement: true },
    LGWAIPRE: { waiverManagment: true, readWaiverPreSaleManagmenet: true},
    AGWAIPRE: { updateWaiverPreSaleManagement: true },
    LREPOLT: { waiverManagment: true, readReports: true },
    CPARAME: { createParameters: true },
    LPARAME: { waiverManagment: true, readParameters: true},
    APARAME: { updateParameters: true},
    BPARAME: { deleteParameters: true },
    LPERAGNLT: { readAgencyProfileLT: true },
    APERAGNLT: { updateAgencyProfileLT: true },
    CGWPRAG: { createAgencyWaiverPreSaleManagement: true },
    LPERGRAGNLT: { readAgencyGroupProfileLT: true },
    CUSRMTZLT: { createAgencyGroupUserManagementLT: true },
    LUSRMTZLT: { readAgencyGroupUserManagementLT: true },
    AUSRMTZLT: { updateAgencyGroupUserManagementLT: true },
    BUSRMTZLT: { deleteAgencyGroupUserManagementLT: true },
    CSWAIPREAGN: { waiverManagment: true, createPreSaleRequest: true },
    LSWAIPREAGN: { waiverManagment: true, readPreSaleRequest: true },
    LREPOAGN: { waiverManagment: true, readAgencyReports: true },
    CSWAIPOSAGN: { waiverManagment: true, createPostSaleRequest: true },
    LSWAIPOSAGN: { waiverManagment: true, readPostSaleRequest: true },
    LGWAIPOSAGN: { waiverManagment: true, readWaiverPostSaleManagementAgency: true },
    LGWAIPREAGN: { waiverManagment: true, readWaiverPreSaleManagementAgency: true },
    LPERAGN: { readAgencyProfile: true },
    CUSRSUCAGN: { createBranchOfficeUser: true },
    LUSRSUCAGN: { readBranchOfficeUser: true },
    AUSRSUCAGN: { updateBranchOfficeUser: true },
    BUSRSUCAGN: { deleteBranchOfficeUser: true },
    LPERGRAGN: { agencyManagment: true, readAgencyGroupProfile: true },
    CUSRMTZAGN: { createAgencyGroupUserManagement: true },
    LUSRMTZAGN: { readAgencyGroupUserManagement: true },
    AUSRMTZAGN: { updateAgencyGroupUserManagement: true },
    BUSRMTZAGN: { deleteAgencyGroupUserManagement: true },
    DLSTUSRLT: { readDownloadUserListLT: true },
    DLSTAGNLT: { downloadAgencyListLT: true },
    CSUCAGNLT: { createBranchOfficeLT: true },
    LPERSUCAGN: { agencyManagment: true, readBranchOfficeProfile: true },
    LPERUSR: { latamManagement: true, readUserProfile: true },
    LPERSUCLT: { readBranchOfficeProfileLT: true },
    CUSRSUCLT: { createBranchOfficeUserLT: true },
    LUSRSUCLT: { readBranchOfficeUserLT: true },
    AUSRSUCLT: { updateBranchOfficeUserLT: true },
    BUSRSUCLT: { deleteBranchOfficeUserLT: true },
    UPLDAGNBDA: { bdaManagment: true },
    LREPBDA: { reprocessBdaLT: true },
    LGMNWA: { waiverManualManagement: true },
    ARWAIMTZAGN: { waiverApproveRejectManual: true },
    LSWAIAGN: { waiverManagment: true, readWaiverRequest: true },
    CSWAIAGN: { waiverManagment: true, createWaiverRequest: true },
    LGWAIAGN: { waiverManagment: true, readWaiverManagementAgency: true },
    LFAREGAGN: { readFareRegulation: true },
    LFAREGLT: { readFareRegulation: true },
    APAXNM: { readCorrectName: true }
  };

  getProfile() {
    this.profiles = new Profile();
    this.tokenTemp = localStorage.getItem('sessionToken');
    this.decodedTokenTemp = jwt_decode(this.tokenTemp);

    this.validateAgencyOrAgent();
    this.validateMatrix();
    this.validateRoleSize();
    this.validateSelfManaged();

    if (this.role_size < this.decodedTokenTemp.user_role_id) {
      this.role_size = this.decodedTokenTemp.user_role_id;
    }

    for (let i = 1; i <= this.role_size; i++) {
      if (+this.decodedTokenTemp.user_role_id === i) {
        for (const entry of this.decodedTokenTemp.allowed_actions) {
          const propertiesToUpdate = this.entryToProperties[entry];
          if (propertiesToUpdate) {
            this.profiles = { ...this.profiles, ...propertiesToUpdate };
          }
        }
        return this.profiles;
      }
    }
  }

  validateSelfManaged() {
    const selfManaged = this.decodedTokenTemp.is_self_managed;
    if (selfManaged != null && selfManaged != undefined){
      this.profiles.isSelfManaged = selfManaged;
    }
  }

  validateRoleSize() {
    if (this.decodedTokenTemp.agency_role_size !== null && this.decodedTokenTemp.agency_role_size !== undefined) {
      this.role_size = this.decodedTokenTemp.agency_role_size;
    } else if (this.decodedTokenTemp.latam_role_size !== null && this.decodedTokenTemp.latam_role_size !== undefined) {
      this.role_size = this.decodedTokenTemp.latam_role_size;
    }
  }

  validateMatrix() {
    if (this.decodedTokenTemp.is_matrix_user !== null &&
      this.decodedTokenTemp.is_matrix_user !== undefined &&
      this.decodedTokenTemp.is_matrix_user === false) {
      this.profiles.roleBranchOffice = true;
    }
  }

  validateAgencyOrAgent() {
    if (this.decodedTokenTemp.is_admin_user === true) {
      this.profiles.roleAgency = true;
    } else if (this.decodedTokenTemp.is_admin_user === false) {
      this.profiles.roleAgent = true;
    }
  }
}
