<ng-container *ngIf="infoUser$ | async as infoUser">
  <div class="container">
    <div class="row">
      <div class="col">
        <nav aria-label="breadcrumb">
          <ol class="breadcrumb">
            <li class="latam-sans breadcrumb-item">
              <a>{{ 'menu.agencyGroupManagement' | translate }}</a>
            </li>
            <li class="breadcrumb-item latam-sans active" aria-current="page">
              <a>{{ 'agencyGroupProfile' | translate }}</a>
            </li>
          </ol>
        </nav>
      </div>
    </div>

    <div class="row">
      <div class="col">
        <span class="title">{{ 'agencyGroupProfile' | translate }}</span>
        <span class="red-title" *ngIf="!loading">{{ group.name }}</span>
      </div>
    </div>

    <hr>

    <ng-container *ngIf="profiles.createBranchOfficeLT">
      <div class="row">
        <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
          <button mat-raised-button id="activarAgenciaButton" (click)="openCreateBranchOfficeDialog()"
            class="background-red w-100">{{ 'button.createBranchOffice' | translate }}</button>
        </div>
      </div>
    </ng-container>

    <!-- spinner -->
    <div *ngIf="loading" class="loading">
      <div class="loading-div mt-5">
        <svg class="spinner" width="50px" height="50px" viewBox="0 0 66 66" xmlns="http://www.w3.org/2000/svg">
          <circle class="path" fill="none" stroke-width="6" stroke-linecap="round" cx="33" cy="33" r="30"></circle>
        </svg>
        <span class="loading-span">{{ 'loading' | translate }}</span>
      </div>
    </div>

    <!-- formulario -->
    <div *ngIf="!loading" class="mt-4 mb-4">
      <mat-accordion>
        <mat-expansion-panel class="profile-group-agency" [expanded]="true">
          <mat-expansion-panel-header>
            <mat-panel-title class="profile-group-agency-title">{{ 'groupInformation' | translate }}</mat-panel-title>
          </mat-expansion-panel-header>
          <!-- Informacion del Grupo de Agencia para LATAM -->
          <div class="row ml-3 mb-3 mr-3">
            <div class="col table-responsive">
              <table class="table table-bordered">
                <tbody>
                  <tr class="table-tuple">
                    <th class="text-nowrap col-w20" scope="row">{{ 'field.agencyGroupName' | translate }}</th>
                    <td class="text-content col-w25">{{ group.name | dashWhenEmpty }}</td>
                    <th class="text-nowrap col-w20" scope="row">{{ 'field.pos' | translate }}</th>
                    <td class="text-content col-w25">
                      <mat-select [compareWith]="compareMarket" #marketsSelect="ngModel" id="marketsSelect"
                        name="marketsSelect" [(ngModel)]="group.market"
                        disabled class="table-select">
                        <mat-option #marketOption *ngFor="let market of markets" [value]="market">
                          {{ market.name }}
                        </mat-option>
                      </mat-select>
                    </td>
                  </tr>

                  <tr class="table-tuple">
                    <th class="text-nowrap" scope="row">{{ 'field.tier' | translate }}</th>
                    <td class="text-content">
                      <mat-select [compareWith]="compareTier" #agencyTier="ngModel" id="agencyTier" name="agencyTier" [(ngModel)]="group.agencyTier" disabled
                        class="table-select">
                        <mat-option #tierOption *ngFor="let groupTier of tierList" [value]="groupTier">
                          {{ groupTier.name }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <th class="text-nowrap" scope="row">{{ 'field.numberOfBranches' | translate }}</th>
                    <td class="text-content">{{ group.branchOfficesNumber }}</td>
                  </tr>

                  <tr class="table-tuple">
                    <th class="text-nowrap" scope="row">{{ 'field.channel2' | translate }}</th>
                    <td class="text-content">
                      <mat-select [compareWith]="compareChannel" #channelSelect="ngModel" id="channelSelect"
                        name="channelSelect"
                        [(ngModel)]="group.agencyChannel" disabled class="table-select">
                        <mat-option #channelOption *ngFor="let channel of channelList" [value]="channel">
                          {{ channel.name }}
                        </mat-option>
                      </mat-select>
                    </td>
                    <th class="text-nowrap" scope="row">{{ 'field.numberOfUsers' | translate }}</th>
                    <td class="text-content">{{ group.userOfficesNumber }}</td>
                  </tr>

                  <tr class="table-tuple">
                    <th class="text-nowrap" scope="row">{{ 'field.adminSalesLatam' | translate }}</th>
                    <td class="text-content">{{ group.adminSales?.name }}</td>
                    <th class="text-nowrap" scope="row">{{ 'field.userSalesLatam' | translate }}</th>
                    <td class="text-content">{{ group.salesExecutive?.name }}</td>
                  </tr>
                  <tr class="table-tuple">
                    <th class="text-nowrap" scope="row">{{ 'field.iata' | translate }}</th>
                    <td class="text-content">{{ group.mainIata }}</td>
                  </tr>
                </tbody>
              </table>

              <div *ngIf="profiles.readAgencyGroupProfileLT" class="row mt-3 mr-3">
                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
                  <mat-checkbox id="allowPreSaleModuleCheckbox" name="allowPreSaleModuleCheckbox"
                    [(ngModel)]="group.allowPreSaleModule" [disabled]="true">{{ 'field.allowPresaleModule' | translate }}
                  </mat-checkbox>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
                  <mat-checkbox id="allowPostSaleModuleCheckbox" name="allowPostSaleModuleCheckbox"
                    [(ngModel)]="group.allowPostSaleModule" [disabled]="true">{{ 'field.allowPostsaleModule' | translate }}
                  </mat-checkbox>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
                  <mat-checkbox class="text-content" id="allowPrivateFareCheckbox" name="allowPrivateFareCheckbox"
                    [(ngModel)]="group.allowPrivateFare" [disabled]="true">{{ 'field.enablePrivateFare' | translate }}</mat-checkbox>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
                  <mat-checkbox id="selManagedCheckbox" name="selManagedCheckbox"
                    [(ngModel)]="group.selfManaged" [disabled]="true">{{ 'field.selfManaged' | translate }}</mat-checkbox>
                </div>

                <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3 text-left">
                  <mat-checkbox id="allowWaiverCheckbox" name="allowWaiverCheckbox"
                    [(ngModel)]="group.allowWaiverModule" [disabled]="true">{{ 'field.allowWaiverModule' | translate }}</mat-checkbox>
                </div>

              </div>
            </div>
          </div>
        </mat-expansion-panel>

        <!-- Informacion del PPTO de Grupo Agencia -->
        <mat-expansion-panel class="profile-group-agency"
          *ngIf="profiles.readBudgetManagement || profiles.readAgencyGroupProfileLT">
          <mat-expansion-panel-header>
            <mat-panel-title class="profile-group-agency-title">{{ 'waiverBudget' | translate }}</mat-panel-title>
          </mat-expansion-panel-header>

          <div class="row mr-3 ml-3">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <mat-form-field>
                <mat-label>{{ 'field.budgetType' | translate }}</mat-label>
                <mat-select id="budgetType"
                  (selectionChange)="selectionBudgetTypeChange($event)">
                  <mat-option *ngFor="let budgetType of budgetTypes" [value]="budgetType">
                    {{ budgetType.name | translateBudgetTypeName }}
                  </mat-option>
                </mat-select>
              </mat-form-field>
            </div>
          </div>

          <app-budget-agency-group #BudgetAgencyGroupComponent [group]="group" [budgetType]="budgetType">
          </app-budget-agency-group>
        </mat-expansion-panel>

        <!-- Tabla agencias Asociadas -->
        <mat-expansion-panel class="profile-group-agency"
          *ngIf="profiles.readAgencyGroupProfileLT || profiles.readAgencyGroupProfile">
          <mat-expansion-panel-header>
            <mat-panel-title class="profile-group-agency-title">{{ 'partnerAgencies' | translate }}</mat-panel-title>
          </mat-expansion-panel-header>

          <app-associated-iatas [matrixId]="group.id" [branchOfficeId]="null"></app-associated-iatas>

          <app-branch-office-matrix [matrixId]="group.id" [budgetTypes]="budgetTypes"></app-branch-office-matrix>
        </mat-expansion-panel>

        <!-- Tabla usuarios -->
        <mat-expansion-panel class="profile-group-agency"
          *ngIf="profiles.readAgencyGroupUserManagement || profiles.readAgencyGroupUserManagementLT">
          <mat-expansion-panel-header>
            <mat-panel-title class="profile-group-agency-title">{{ 'users' | translate }}</mat-panel-title>
          </mat-expansion-panel-header>

          <div *ngIf="profiles.createAgencyGroupUserManagement || profiles.createAgencyGroupUserManagementLT"
            class="row ml-3 mb-3 mr-3">
            <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
              <button mat-raised-button id="createUserButton" (click)="openCreateUserDialog()"
                class="background-red w-100">
                <span>{{ 'menu.createUser' | translate }}</span>
              </button>
            </div>
          </div>

          <app-user-list [matrixId]="group.id" [reloadUsers]="reloadUsers" [typeUser]="'matrix'" class="mb-3">
          </app-user-list>
        </mat-expansion-panel>
      </mat-accordion>

      <ng-container *ngIf="infoUser.isLatamSession()">
        <div class="row mt-4 mb-4">
          <div class="col-sm-6 col-md-4 col-lg-3 col-xl-3">
            <button mat-raised-button id="backButton" (click)="returnToList()"
              class="btn-volver w-100">{{ 'button.back' | translate }}</button>
          </div>
        </div>
      </ng-container>
    </div>
  </div>
</ng-container>
