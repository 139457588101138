import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { StorageService } from './storage.service';
import { Observable } from 'rxjs';
import { FareDetailResponse } from '../interfaces/fare-detail-response.interface';

@Injectable({
  providedIn: 'root'
})
export class FareRegulationService {

  urlBase = `${StorageService.getDnsLatamtrade()}${environment.endpoints.urlFareRegulation}`;

  constructor(
    private readonly http: HttpClient
  ) { }

  getFareDetails(identifier: string, searchBy: string, groupId: number): Observable<FareDetailResponse> {
    return searchBy === 'PNR'
      ? this.getFareDetailsByRecordLocator(identifier, groupId)
      : this.getFareDetailsByOrderId(identifier, groupId);
  }


  getFareDetailsByRecordLocator(recordLocator: string, groupId: number): Observable<FareDetailResponse> {
    let params = new HttpParams();
    if (groupId != null) {
      params = params.append("groupId", groupId);
    }
    return this.http.get<FareDetailResponse>(`${this.urlBase}/order/pnr/${recordLocator}/details`, {params});
  }

  getFareDetailsByOrderId(orderId: string, groupId: number): Observable<FareDetailResponse> {
    let params = new HttpParams();
    if (groupId != null) {
      params = params.append("groupId", groupId);
    }
    return this.http.get<FareDetailResponse>(`${this.urlBase}/order/${orderId}/details`, {params});
  }


  identifyFare(fareBasisCode: string) {
    const chars4and5 = fareBasisCode.substring(3, 5);
    const char7 = fareBasisCode.charAt(6);

    const fbMercadoInter = {
      'QP': 'BASIC',
      'SL': 'LIGHT',
      'KO': 'CLASSIC',
      'KM': 'STANDARD',
      'KD': 'FULL',
      'RY': 'PREMIUM ECONOMY',
      'EJ': 'PREMIUM BUSINESS',

      'PX': 'BASIC',
      'PF': 'LIGHT',
      'PE': 'FULL',
      'PP': 'FULL',
      'PY': 'PREMIUM ECONOMY',
      'PL': 'PREMIUM ECONOMY',
      'PJ': 'PREMIUM BUSINESS'
    };

    const fbMercadoDomBr = {
      '5': 'BASIC',
      '1': 'LIGHT',
      '6': 'CLASSIC',
      '4': 'STANDARD',
      '7': 'FULL',
      'A': 'PREMIUM ECONOMY',
      'J': 'PREMIUM BUSINESS'
    };

    return fbMercadoInter[chars4and5] || fbMercadoDomBr[char7] || null;
  }
}
