<div class="search-main-container">
  <div class="test">
    <span class="search-by">{{ 'waiverLatam.searchBy' | translate }}</span>
    <div class="search-inputs">
      <form>
        <mat-form-field appearance="outline" >
          <mat-select [(value)]="searchBy">
              <mat-option value="PNR">PNR</mat-option>
              <mat-option value="ORDER">{{ 'fareRegulation.orderNumber' | translate}}</mat-option>
          </mat-select>
      </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="searchBy === 'PNR'">
          <mat-label>PNR</mat-label>
          <input matInput maxlength="6" [(ngModel)]="identifier" #myInput="ngModel"  (ngModelChange)="identifier = $event.toUpperCase()">
          <mat-error *ngIf="myInput.invalid && (myInput.dirty || myInput.touched)">
            <span *ngIf="myInput.errors?.['required']">This field is required</span>
          </mat-error>
        </mat-form-field>

        <mat-form-field appearance="outline" *ngIf="searchBy === 'ORDER'">
          <mat-label>{{ 'fareRegulation.orderNumber' | translate}}</mat-label>
          <input matInput maxlength="18" [(ngModel)]="identifier" (ngModelChange)="identifier = $event.toUpperCase()"> 
        </mat-form-field>

        <button mat-flat-button (click)="searchOrderFareDetails()" [disabled]="loading">
          <span *ngIf="!loading">
            {{'button.search' | translate}}
          </span>
          <mat-progress-spinner *ngIf="loading" class="custom-spinner" mode="indeterminate" diameter="20">
          </mat-progress-spinner>
        </button>

        <button mat-flat-button (click)="clearRecordLocator()">
          <span>
            {{'button.clear' | translate}}
          </span>
        </button>
      </form>
    </div>
  </div>

  <div class="info" *ngIf="!fareInfoLoaded">
    <img src="assets/images/waiver-latam/search.svg" />
    <h2>
    </h2>
    <p [innerHTML]="'fareRegulation.moduleDescription' | translate"></p>
    <div class="list">
      <ul>
        <li>{{ 'fareRegulation.ticketInfo' | translate }}</li>
        <li>{{ 'fareRegulation.fareRulesInfo' | translate }}</li>
        <li>{{ 'fareRegulation.itineraryInfo' | translate }}</li>
        <li>{{ 'fareRegulation.passengerData' | translate }}</li>
        <li>{{ 'fareRegulation.bagggageSeats' | translate }}</li>
      </ul>
    </div>
      <p [innerHTML]="'fareRegulation.accessInfo' | translate"></p>
  </div>

  <div class="fare-detail-container" *ngIf="fareInfoLoaded">

    <div class="info-title">
      {{'fareRegulation.paymentDetail.title' | translate}}
    </div>

    <div class="summary">

      <img src="assets/images/fare-regulation/travel.svg" alt="person" />
      <div class="payment-detail" style="margin-left: 1rem">
        <span class="latam-regular" style="font-size: 14px">{{'fareRegulation.paymentDetail.currency' | translate}}</span>
        <span class="latam-bold indigo-4f" style="font-size: 20px">{{
          fareDetails.paymentSummary?.flightTicketsTotal?.total?.currency }}</span>
      </div>

      <div class="payment-detail" style="margin-left: 1rem">
        <span class="latam-regular" style="font-size: 14px">{{'fareRegulation.paymentDetail.ancillariesCost' | translate}}</span>
        <span class="latam-bold indigo-4f" style="font-size: 20px">{{
          fareDetails.paymentSummary?.ancillaryTicketsTotal?.total?.formattedValue ? fareDetails.paymentSummary?.ancillaryTicketsTotal?.total?.formattedValue : 0 }}</span>
      </div>


      <div class="payment-detail" style="margin-left: 1rem">
        <span class="latam-regular" style="font-size: 14px">{{'fareRegulation.paymentDetail.ticketsCost' | translate}}</span>
        <span class="latam-bold indigo-4f" style="font-size: 20px">{{
          fareDetails.paymentSummary?.flightTicketsTotal?.total?.formattedValue }}</span>
      </div>

      <div class="payment-detail" style="margin-left: 1rem">
        <span latam-regular style="font-size: 14px">{{'fareRegulation.paymentDetail.totalCost' | translate}}</span>
        <span class="latam-bold coral" style="font-size: 20px">
          {{ fareDetails.paymentSummary?.ancillaryTicketsTotal?.total?.value +
          fareDetails.paymentSummary?.flightTicketsTotal?.total?.value | number:'1.0-2':'es'}}
        </span>
      </div>

    </div>


    <div class="flight-container">

      <div class="flight-info">
        <div class="info-title">
          {{'fareRegulation.flightItinerary.title' | translate}}
        </div>
    
        <div class="itinerary">
          <mat-tab-group class="itinerary-tab-group" #itineraryGroup (selectedTabChange)="resetSegmentIndex($event)">
            <mat-tab *ngFor="let itinerary of fareDetails.flightDetails; let i = index">
              <ng-template mat-tab-label>
                <div class="tab-label latam-bold">
                  {{itinerary.originAirport.city + ', ' + itinerary.originAirport.country + '(' +
                  itinerary.originAirport.iata + ')'}}
                  <mat-icon>
                    arrow_right_alt
                  </mat-icon>
                  {{itinerary.destinationAirport.city + ', ' + itinerary.destinationAirport.country + '(' +
                  itinerary.destinationAirport.iata + ')'}}
                </div>
              </ng-template>
    
              <div class="itinerary-content">
                <div class="segments-container" *ngIf="itinerary.segments[currentSegmentIndex] as segment">
                  <div class="segment-info">
                    <div class="route-info">
                      <div class="route-info-title">
                        <img src="assets/images/fare-regulation/air-shuttle.svg" alt="air-shuttle" />
                        <span class="latam-regular">{{'fareRegulation.flightItinerary.flight' | translate }} {{'fareRegulation.flightItinerary.from' | translate }} </span>
                        <span class="latam-bold">{{ segment.origin.city }} ({{ segment.origin.iata }}) </span>
                        <span class="latam-regular">{{'fareRegulation.flightItinerary.to' | translate }} </span>
                        <span class="latam-bold">{{ segment.destination.city }} ({{ segment.destination.iata }}) </span>
                        <span class="latam-regular">({{ currentSegmentIndex + 1 }} de {{ itinerary.segments.length
                          }})</span>
                      </div>
    
    
                      <table>
                        <tr>
                          <td class="latam-bold">{{'fareRegulation.flightItinerary.departure' | translate}}:</td>
                          <td> {{ segment.departureDate | date:'dd/MM/yy' }} | {{ segment.departureDate | date:'shortTime'
                            }}</td>
                        </tr>
                        <tr>
                          <td class="latam-bold">{{'fareRegulation.flightItinerary.arrival' | translate}}:</td>
                          <td> {{ segment.arrivalDate | date:'dd/MM/yy' }} | {{ segment.arrivalDate | date:'shortTime' }}
                          </td>
                        </tr>
                        <tr>
                          <td class="latam-bold">{{'fareRegulation.flightItinerary.duration' | translate}}:</td>
                          <td> {{ segment.duration }} min</td>
                        </tr>
                        <tr>
                          <td class="latam-bold">{{'fareRegulation.flightItinerary.cabin' | translate}}:</td>
                          <td> {{ segment.cabin }}</td>
                        </tr>
                        <tr>
                          <td class="latam-bold">{{'fareRegulation.flightItinerary.fare' | translate}}:</td>
                          <td> {{ getFare() }}</td>
                        </tr>
                      </table>
                    </div>
    
                  </div>
                </div>
    
                <div class="segment-navigator" *ngIf="itinerary.segments.length > 1">
                  <div class="segment-navigator-item">
                    <a mat-icon-button *ngIf="currentSegmentIndex != 0" (click)="previousSegment()">
                      <mat-icon>chevron_left</mat-icon>
                    </a>
                    <span *ngIf="currentSegmentIndex !== 0">
                      {{'fareRegulation.flightItinerary.previousSegment' | translate}}
                    </span>
                  </div>
    
                  <div class="segment-navigator-item">
                    <span [style.color]="currentSegmentIndex === itinerary.segments.length - 1 ? 'rgba(0, 0, 0, 0.38)' : ''">
                      {{'fareRegulation.flightItinerary.nextSegment' | translate}}
                    </span>
                    <a mat-icon-button
                      [disabled]="currentSegmentIndex === itinerary.segments.length - 1" (click)="nextSegment()">
                      <mat-icon>chevron_right</mat-icon>
                    </a>
                  </div>
                </div>
    
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
      <div class="fare-rules-container">
        <div class="info-title">
          {{'fareRegulation.fareRules.title' | translate}}
        </div>
        <div class="fare-rules-info">
          <mat-tab-group class="fare-tab-group">
            <mat-tab>
              <ng-template mat-tab-label>
                <div class="normal-spacing latam-bold indigo-4f">
                  {{'fareRegulation.fareRules.changes' | translate}}
                </div>
              </ng-template>
              <div *ngFor="let rule of fareDetails.flightDetails[currentItineraryIndex].segments[currentSegmentIndex]?.fareRules" class="rule-item">            
                <div class="fees-section">
                    <div>
                        <div>
                          <span class="latam-bold indigo-4f">{{'fareRegulation.fareRules.beforeFlight' | translate}}</span>
                          <ul>
                            <li *ngIf="!rule.changesFees.beforeFee.allowed">
                              {{ 'fareRegulation.fareRules.changeNotAllowed' | translate }}
                            </li>
                            <li *ngIf="rule.changesFees.beforeFee.allowed && rule.changesFees.beforeFee.penalty.amount.value>0" class="latam-regular">
                              {{ 'fareRegulation.fareRules.changeAllowedWithPenalty' | translate:{value: rule.changesFees.beforeFee.penalty.amount.formattedValue } }}
                            </li>
                            <li *ngIf="rule.changesFees.beforeFee.allowed && rule.changesFees.beforeFee.penalty.amount.value<=0" class="latam-regular">
                              {{ 'fareRegulation.fareRules.changeAllowedWithoutPenalty' | translate }}
                            </li>
                          </ul>
    
                        </div>
                        <div>
                          <span class="latam-bold indigo-4f">{{'fareRegulation.fareRules.afterFlight' | translate}}</span>
                          <ul>
                            <li *ngIf="!rule.changesFees.afterFee.allowed" class="latam-regular">
                              {{ 'fareRegulation.fareRules.changeNotAllowed' | translate }}
                            </li>
                            <li *ngIf="rule.changesFees.afterFee.allowed && rule.changesFees.afterFee.penalty.amount.value>0" class="latam-regular">
                              {{ 'fareRegulation.fareRules.changeAllowedWithPenalty' | translate:{value: rule.changesFees.afterFee.penalty.amount.formattedValue } }}
                            </li>
                            <li *ngIf="rule.changesFees.afterFee.allowed && rule.changesFees.afterFee.penalty.amount.value<=0" class="latam-regular">
                              {{ 'fareRegulation.fareRules.changeAllowedWithoutPenalty' | translate }}
                            </li>
                          </ul>
                        </div>
                    </div>
                </div>
              </div>
            </mat-tab>

            <mat-tab>
                <ng-template mat-tab-label>
                  <div class="normal-spacing latam-bold indigo-4f">
                    {{'fareRegulation.fareRules.refund' | translate}}
                  </div>
              </ng-template>
              <div *ngFor="let rule of fareDetails.flightDetails[currentItineraryIndex].segments[currentSegmentIndex]?.fareRules" class="rule-item">            
                <div class="fees-section">
                  <div>
                    <div>
                      <span class="latam-bold indigo-4f">{{'fareRegulation.fareRules.beforeFlight' | translate}}</span>
                      <ul>
                        <li *ngIf="!rule.refundFees.beforeFee.allowed" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundNotAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.beforeFee.allowed && rule.refundFees.beforeFee.penalty.amount.value == 100" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundNotAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.beforeFee.allowed && rule.refundFees.beforeFee.penalty.amount.value == 0" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.beforeFee.allowed" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundBoardingFees' | translate }}
                        </li>
                      </ul>

                    </div>
                    <div>
                      <span class="latam-bold indigo-4f">{{'fareRegulation.fareRules.afterFlight' | translate}}</span>
                      <ul>
                        <li *ngIf="!rule.refundFees.afterFee.allowed" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundNotAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.afterFee.allowed && rule.refundFees.afterFee.penalty.amount.value == 100" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundNotAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.afterFee.allowed && rule.refundFees.afterFee.penalty.amount.value == 0" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundAllowed' | translate }}
                        </li>
                        <li *ngIf="rule.refundFees.afterFee.allowed" class="latam-regular">
                          {{ 'fareRegulation.fareRules.refundBoardingFees' | translate }}
                        </li>
                      </ul>
                    </div>
                </div>
                </div>
              </div>
            </mat-tab>
          </mat-tab-group>
        </div>
      </div>
    </div>

    <div class="info-title">
      {{ 'fareRegulation.passenger.title' | translate }}
    </div>

    <div *ngIf="fareDetails.passengers[currentPassengerIndex] as passenger">
      <div class="passenger">
        <div class="passenger-info">
          <img src="assets/images/fare-regulation/human.svg" alt="person" />
          <div class="passenger-info-summary">
            <span class="p-title">{{ 'fareRegulation.passenger.name' | translate }} {{currentPassengerIndex + 1}}</span>
            <span class="p-name latam-bold">{{ passenger.name }}</span>
            <span class="p-email">{{ passenger.email }}</span>
          </div>
        </div>
        <div class="passenger-data">
          <div class="passenger-info-summary">
            <span class="p-title">{{ 'fareRegulation.passenger.phoneNumber' | translate }}</span>
            <span class="p-name">{{ passenger.phoneNumber }}</span>
          </div>
  
  
          <div class="passenger-info-summary">
            <span class="p-title">{{ 'fareRegulation.passenger.ticketNumber' | translate }}</span>
            <span class="p-name">{{ findPassengerTkt(passenger?.tickets)?.ticketNumber }}</span>
          </div>
  
          <div class="passenger-info-summary">
            <span class="p-title">{{ 'fareRegulation.passenger.status' | translate }}</span>
            <span class="p-name latam-bold">{{ findPassengerTkt(passenger?.tickets)?.status }} </span>
          </div>
        </div>
      </div>
      <div class="segment-navigator centered" *ngIf="fareDetails.passengers.length > 1">
        <div class="segment-navigator-item">
          <a mat-icon-button *ngIf="currentPassengerIndex != 0" (click)="previousPassenger()">
            <mat-icon>chevron_left</mat-icon>
          </a>
          <span *ngIf="currentPassengerIndex !== 0">
            {{ 'fareRegulation.passenger.previous' | translate }}
          </span>
        </div>

        <div class="segment-navigator-item">
          <span [style.color]="currentPassengerIndex === fareDetails.passengers.length - 1 ? 'rgba(0, 0, 0, 0.38)' : ''">
            {{ 'fareRegulation.passenger.next' | translate }}
          </span>
          <a mat-icon-button
            [disabled]="currentPassengerIndex === fareDetails.passengers.length - 1" (click)="nextPassenger()">
            <mat-icon>chevron_right</mat-icon>
          </a>
        </div>
      </div>
    </div>

    <div class="passenger-ancillaries">
        <mat-expansion-panel class="ancillary-item" #baggage [(expanded)]="baggagePanelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title class="latam-bold indigo-4f">
              <img src="assets/images/fare-regulation/man-with-luggage.svg" alt="person" style="margin-right: 1rem"/>
              {{ 'fareRegulation.baggage.passengerBaggage' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>


          <div style="margin-left: 1rem;" *ngFor="let coupon of findPassengerTkt(fareDetails?.passengers[currentPassengerIndex].tickets).coupons; let i = index">
            <div *ngIf="isCurrentRoute(coupon)">  
              <div class="baggage-info-container">
                <div class="baggage-included">
                    <span class="baggage-title latam-bold indigo-4f">Equipaje incluido</span>
                    <div class="latam-regular">
                      <img src="assets/images/fare-regulation/personal-item.svg" alt="person" />
                       1 {{ 'fareRegulation.baggage.smallBag' | translate }}
                    </div>
                    <div *ngFor="let carryOnBaggage of coupon.baggage?.carryOnBaggage">
                      <div *ngIf="carryOnBaggage.units >= 1" class="latam-regular">
                        <img src="assets/images/fare-regulation/hand-luggage.svg" alt="person" />
                        {{carryOnBaggage.units}} {{ 'fareRegulation.baggage.handLuggage' | translate }} {{carryOnBaggage.weight.value}}kg
                      </div>
                    </div>
                    <div *ngFor="let checkedBaggage of coupon.baggage?.checkedBaggage">
                      <div *ngIf="checkedBaggage.units >= 1" class="latam-regular">
                        <img src="assets/images/fare-regulation/checked-luggage.svg" alt="person" />
                        {{checkedBaggage.units}} {{ 'fareRegulation.baggage.checkedLuggage' | translate }} {{checkedBaggage.weight.value}}kg
                      </div>
                    </div>
                </div>
                <div class="baggage-extra" style="margin-left: 1rem;">
                  <span class="baggage-title latam-bold indigo-4f">{{ 'fareRegulation.baggage.additionalBaggage' | translate }}</span>
                  <div *ngFor="let aditionalBaggage of getAdditionalBaggage()" class="latam-regular">
                    {{aditionalBaggage.quantity}} {{aditionalBaggage.description}}
                  </div>
                  <div *ngIf="getAdditionalBaggage().length == 0" class="latam-regular">
                    {{ 'fareRegulation.baggage.withoutAdditionalBaggage' | translate }}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </mat-expansion-panel>

        
        <mat-expansion-panel class="ancillary-item" #seats [(expanded)]="seatsPanelOpenState">
          <mat-expansion-panel-header>
            <mat-panel-title class="latam-bold indigo-4f">
              <img src="assets/images/fare-regulation/seat.svg" alt="person"  style="margin-right: 1rem"/>
              {{ 'fareRegulation.passenger.seat' | translate }}
            </mat-panel-title>
          </mat-expansion-panel-header>
          <div style="margin-left: 1rem;" *ngFor="let coupon of findPassengerTkt(fareDetails?.passengers[currentPassengerIndex].tickets).coupons; let i = index">
            <div *ngIf="isCurrentRoute(coupon)">

              <div class="seat-container" *ngIf="coupon?.seatCode !== '' && coupon?.seatCode != null">
                  <div class="seat-code">
                    <span>{{ coupon?.seatCode }}</span>
                  </div>
                  <div class="latam-regular" style="margin-left: 1rem;">
                    {{ fareDetails?.passengers[currentPassengerIndex].name}}
                  </div>
              </div>

              <div class="seat-container" *ngIf="coupon?.seatCode === '' || coupon?.seatCode == null">
                <span class="latam-regular">{{ 'fareRegulation.passenger.withoutSeat' | translate }}</span>
              </div>
              
            </div>
          </div>
        </mat-expansion-panel>
    </div>
  </div>
</div>